<template>
  <div id="formEntry">
    <Sheet :value="value" :width="formWidth" no-padding @input="closeSheet">
      <template #title>
        <slot name="title">{{ title }}</slot>
      </template>

      <template #actions>
        <BaseActionButton
          is-flat
          :icon="isMaximized ? 'eva-collapse' : 'eva-expand'"
          class="q-mr-sm"
          @click="toggleWidth"
        />
      </template>

      <template #default>
        <!-- form -->

        <div id="content-wrapper" class="row">
          <div class="col">
            <BaseScrollbar height="calc(100vh - 60px)" class="q-pa-md">
              <RenderForm
                no-padding
                :form-id="formId"
                :panels="panels"
                :secondary-panels="secondaryPanels"
                :form-settings="formSettings"
                :form-model="formModel"
                :is-readonly="mode === 'VIEW'"
                :form-upload-files="formUploadFiles"
                :enable-controls="_enableControls"
                @uploadFiles="uploadFilesFromForm"
                @save="saveFormEntry"
                @deletedFiles="deletedFiles"
              />
            </BaseScrollbar>
          </div>
          <div class="col-auto q-mr-sm leftBorder">
            <BaseActionButton
              v-for="tab in tabs"
              :key="tab.id"
              v-tooltip.left="tab.label"
              is-flat
              no-border
              :icon="tab.icon"
              color="primary"
              class="tabButtons"
              @click="showLinks(tab.value)"
            >
              <span v-if="tab.count" class="badge">{{ tab.count }} </span>
            </BaseActionButton>
          </div>
        </div>

        <QRView
          v-model="isQRView"
          :form-id="formId"
          :form-entry-id="formEntryId"
          @input="closeQRModal"
        />

        <!-- ... -->
      </template>
    </Sheet>
    <Sheet
      v-model="attachmentSheet"
      width="30vw"
      :sub-actions="attachmentList.length ? true : false"
      @input="closeAttachmentsSheet"
    >
      <!-- title -->

      <template #title> Attachments </template>

      <template #actions>
        <BaseActionButton
          v-tooltip.top="'refresh'"
          icon="mdi-refresh"
          color="secondary"
          no-border
          is-flat
          size="20px"
        />

        <BaseActionButton
          v-tooltip.top="'upload documents'"
          icon="mdi-upload"
          color="secondary"
          no-border
          is-flat
          size="20px"
          class="q-mr-sm"
          @click="$refs.attachments.click()"
        />
      </template>

      <template #subActions>
        <template>
          <CheckboxField
            v-model="selectAll"
            class="col-auto q-pt-sm q-pb-sm"
            @input="fileSelectAll"
          />
          Select All
        </template>

        <BaseActionButton
          v-if="selectedFileCount && false"
          v-tooltip.top="'mail share'"
          icon="mdi-email-send-outline"
          color="secondary"
          no-border
          is-flat
          size="18px"
          class="q-ml-sm"
          @click="emailSharingSheet = true"
        />

        <BaseActionButton
          v-if="selectedFileCount > 1 && false"
          v-tooltip.top="'merge documents'"
          icon="mdi-file-document-multiple-outline"
          color="secondary"
          no-border
          is-flat
          size="18px"
        />
      </template>

      <!-- ... -->

      <!-- content -->
      <div id="content-wrapper">
        <input
          ref="attachments"
          type="file"
          class="hidden"
          @change="uploadAttachments"
        />
        <template v-for="file in attachmentList">
          <div :key="file.id" class="attachmentList">
            <div class="title row">
              <div class="col-auto">
                <!-- <CheckboxField v-model="file.checked" class="col-auto" /> -->
                <BaseIcon
                  :name="
                    file.checked
                      ? 'eva-checkmark-circle-2'
                      : 'eva-radio-button-off-outline'
                  "
                  color="secondary"
                  class="q-mr-sm"
                  @click="file.checked = !file.checked"
                />
              </div>
              <div class="col-auto">
                <FileIcon
                  :mime-type="fileType(file.name)"
                  class="mini-avatar q-mr-sm"
                />
              </div>
              <div class="col ellipsis" @click="openFile(file)">
                {{ file.name }}
              </div>
              <div class="col-auto">
                <BaseActionButton
                  v-tooltip.top="'delete'"
                  icon="eva-trash-2-outline"
                  color="red"
                  no-border
                  is-flat
                  size="20px"
                  @click.stop="attachmentDelete(file)"
                />
              </div>
            </div>

            <div class="meta">
              <!-- created at -->

              <div>{{ $day.format(file.createdAt) }}</div>

              <!-- ... -->

              <!-- created by -->

              <template v-if="file.createdBy">
                <BaseIcon name="mdi-circle-small" />
                <div>{{ file.createdByEmail }}</div>
              </template>

              <!-- ... -->

              <!-- size -->

              <template v-if="file.size">
                <BaseIcon name="mdi-circle-small" />
                <div>{{ fileSize(file.size) }}</div>
              </template>

              <!-- ... -->
            </div>
          </div>
        </template>
      </div>
    </Sheet>
    <Sheet
      v-model="commentsSheet"
      width="30vw"
      height="80vh"
      custom-height="30"
      @input="closeCommentsSheet"
    >
      <!-- title -->

      <template #title> Comments </template>

      <!-- ... -->

      <!-- content -->
      <div id="content-wrapper">
        <BaseScrollbar height="calc(100vh - 155px)">
          <!-- comments -->
          <template v-for="(row, idx) in commentsList">
            <q-chat-message
              :key="idx"
              :name="
                row.createdBy === $store.state.session.id
                  ? 'me'
                  : row.createdByEmail
              "
              :text="[row.comments]"
              :sent="row.createdBy === $store.state.session.id"
              :stamp="lastCommentDuration(row)"
              :bg-color="
                row.createdBy === $store.state.session.id
                  ? 'primary-1'
                  : 'secondary-1'
              "
              class="q-ma-sm"
            />
          </template>
          <!-- ... -->
        </BaseScrollbar>
      </div>

      <!-- ... -->

      <div class="col q-pa-sm borderTop">
        <div class="row">
          <!-- <div class="col-auto" style="margin: auto">
            <q-avatar
              color="primary-11 q-mr-sm"
              text-color="white"
              icon="mdi-comment-account-outline"
              size="32px"
            />
          </div> -->
          <div class="col">
            <TextAreaField
              v-model="commentText"
              auto-focus
              placeholder="Type a new comment..."
            />
          </div>
          <div class="col-auto" style="margin: auto">
            <BaseActionButton
              v-tooltip.left="'Post Comments'"
              icon="eva-paper-plane-outline"
              color="primary-11"
              class="q-ml-sm commentAction"
              size="16px"
              rotate-class="rotate-45"
              @click="saveComment"
            />
            <BaseActionButton
              v-tooltip.left="'Advanced Comments'"
              icon="mdi-comment-text-multiple"
              color="primary-11"
              class="q-ml-sm q-mt-sm commentAction"
              size="16px"
              @click="advancedCommentsSheet = true"
            />
          </div>
        </div>
      </div>
    </Sheet>
    <Sheet
      v-model="fileSheet"
      no-padding
      width="100vw"
      @input="fileSheet = false"
    >
      <!-- title -->

      <template #title>
        <div class="attachmentFileHeader">
          <div class="avatar q-ml-sm">
            <FileIcon :mime-type="fileType(selectedFile.name)" />
          </div>

          <div class="q-ml-md">
            <div class="filename">
              {{ selectedFile.name }}
            </div>

            <div class="meta">
              <div>Archived At: {{ $day.format(selectedFile.createdAt) }}</div>
              <BaseIcon name="mdi-circle-small" />
              <div>Archived By: {{ selectedFile.createdByEmail }}</div>
              <div v-if="selectedFile.size">
                <BaseIcon name="mdi-circle-small" />
                File Size: {{ fileSize(selectedFile.size) }}
              </div>
            </div>
          </div>
        </div>
      </template>

      <div class="attachmentFileFrame">
        <div class="file-list">
          <iframe
            id="file-frame"
            ref="myFiles"
            :src="fileLink()"
            frameborder="0"
          ></iframe>
        </div>
      </div>
    </Sheet>
    <Modal
      v-model="advancedCommentsSheet"
      width="60vw"
      height="80vh"
      has-footer
      @input="advancedCommentsSheet = false"
    >
      <!-- title -->

      <template #title> Comments</template>

      <!-- ... -->

      <template #default>
        <TextBuilderField v-model="advancedCommentText" />
      </template>

      <template #footer>
        <BaseButton
          label="Post"
          icon="eva-paper-plane-outline"
          color="primary"
          @click="saveAdvancedComment"
        />
      </template>
    </Modal>
  </div>
</template>

<script>
import Sheet from "@/components/common/popup/Sheet.vue";
import RenderForm from "@/components/common/render-form/RenderForm.vue";
import QRView from "@/components/common/QRView.vue";
import CheckboxField from "@/components/common/form/checkbox-field/CheckboxField.vue";
import TextAreaField from "@/components/common/form/text-area-field/TextAreaField.vue";
import { form, uploadAndIndex, repository } from "@/api/factory.js";
import { omit } from "lodash-es";
import FileIcon from "@/components/common/FileIcon.vue";
import formatBytes from "@/helpers/format-bytes.js";
import { pdfSupport, htmlSupport, fileSupport } from "@/helpers/file-format.js";
import axios from "axios";
import TextBuilderField from "@/components/common/form/text-builder-field/TextBuilderField.vue";
import Modal from "@/components/common/popup/Modal.vue";

export default {
  name: "EntrySheet",

  components: {
    Sheet,
    RenderForm,
    QRView,
    FileIcon,
    CheckboxField,
    TextAreaField,
    TextBuilderField,
    Modal,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    mode: {
      type: String,
      default: "NEW",
    },

    formId: {
      type: String,
      default: "",
    },

    form: {
      type: [Object, null],
      default: null,
    },

    formModel: {
      type: Object,
      default: () => {},
    },

    repositoryId: {
      type: Number,
      default: 0,
    },

    formFields: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      formWidth: "720px",
      isQRView: false,
      formEntryId: 0,
      tabs: [
        {
          id: this.$nano.id(),
          label: "attachments",
          value: "ATTACHMENTS",
          icon: "mdi-attachment",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "comments",
          value: "COMMENTS",
          icon: "mdi-comment-text-outline",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "history",
          value: "HISTORY",
          icon: "mdi-history",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "print",
          value: "PRINT",
          icon: "mdi-printer",
          count: 0,
        },
      ],
      attachmentSheet: false,
      commentsSheet: false,
      commentText: "",
      commentsList: [],
      selectAll: false,
      attachmentLoad: null,
      attachmentList: [],
      fileSheet: false,
      selectedFile: {},
      advancedCommentsSheet: false,
      advancedCommentText: "",
      formUploadFiles: [],
      fileControlDeleteFile: [],
    };
  },

  computed: {
    isMaximized() {
      return this.formWidth === "100vw";
    },

    title() {
      if (this.mode === "VIEW") return "View Entry";
      if (this.mode === "EDIT") return "Edit Entry";

      return "Add Entry";
    },

    panels() {
      if (!this.form) {
        return [];
      }

      return this.form.panels;
    },

    secondaryPanels() {
      if (!this.form) {
        return [];
      }

      return this.form.secondaryPanels;
    },

    formSettings() {
      if (!this.form) {
        return {};
      }

      return this.form.settings;
    },

    selectedFileCount() {
      let count = this.attachmentList.filter((item) => item.checked).length;
      return count;
    },

    _enableControls() {
      let enableControls = [];
      let listControls = [];
      this.panels.forEach((panel) => {
        if (panel.fields.length) {
          let panelFields = panel.fields.filter(
            (field) =>
              field.type === "SINGLE_SELECT" ||
              field.type === "SINGLE_CHOICE" ||
              field.type === "MULTIPLE_CHOICE" ||
              field.type === "MULTI_SELECT"
          );
          if (panelFields.length) {
            panelFields.forEach((row) => {
              if (row.settings.validation.enableSettings) {
                if (row.settings.validation.enableSettings.length) {
                  let controls = [];
                  row.settings.validation.enableSettings.forEach((tblrow) => {
                    if (tblrow.controls.length) {
                      controls.push({
                        value: tblrow.value,
                        childControls: tblrow.controls,
                      });
                      tblrow.controls.forEach((control) => {
                        if (
                          controls.indexOf(control) < 0 &&
                          listControls.indexOf(control) < 0
                        ) {
                          listControls.push(control);
                        }
                      });
                    }
                  });
                  if (controls.length) {
                    enableControls.push({
                      id: row.id,
                      parentControls: controls,
                    });
                  }
                }
              }
            });
          }
        }
      });
      return {
        listControls: listControls,
        controls: enableControls,
      };
    },
  },

  watch: {
    formModel: {
      immediate: true,
      handler() {
        this.attachmentList = [];
        this.getComments();
        this.getAttachments();
      },
    },

    attachmentSheet: {
      immediate: true,
      handler() {
        if (this.attachmentSheet && this.formModel.id) {
          //this.attachmentLoad = setInterval(() => this.getAttachments(), 5000);
        } else {
          clearInterval(this.attachmentLoad);
        }
      },
    },
  },

  methods: {
    toggleWidth() {
      this.formWidth = this.formWidth === "100vw" ? "720px" : "100vw";
    },

    closeSheet() {
      this.$emit("input", false);
    },

    closeAttachmentsSheet() {
      this.attachmentSheet = false;
    },

    closeCommentsSheet() {
      this.closeCommnetsSheet = false;
    },

    showLinks(tab) {
      if (tab === "ATTACHMENTS") {
        this.attachmentSheet = true;
      } else if (tab === "COMMENTS") {
        this.commentsSheet = true;
      } else if (tab === "HISTORY") {
        //this.$emit("history", taskId);
      } else if (tab === "PRINT") {
        window.print();
      }
    },

    lastCommentDuration(comment) {
      const date =
        comment.createdAt instanceof Date
          ? comment.createdAt
          : new Date(comment.createdAt);
      const formatter = new Intl.RelativeTimeFormat("en");
      const ranges = {
        years: 3600 * 24 * 365,
        months: 3600 * 24 * 30,
        weeks: 3600 * 24 * 7,
        days: 3600 * 24,
        hours: 3600,
        minutes: 60,
        seconds: 1,
      };
      const secondsElapsed = (date.getTime() - Date.now()) / 1000;
      for (let key in ranges) {
        if (ranges[key] < Math.abs(secondsElapsed)) {
          const delta = secondsElapsed / ranges[key];
          return formatter.format(Math.round(delta), key);
        }
      }
    },

    fileSelectAll() {
      this.attachmentList = this.attachmentList.map((item) => {
        return {
          ...item,
          checked: this.selectAll,
        };
      });
    },

    showHistory() {
      //this.$emit("history", processId);
    },

    fileType(fileName) {
      if (fileName) return fileName.split(".").reverse()[0].slice(0, 4);
      else "";
    },

    fileSize(size) {
      return formatBytes(size);
    },

    fileLink() {
      let origin = location.origin.replace(
        "http://localhost:8080",
        "http://52.172.32.88"
      );
      if (this.selectedFile.name) {
        let ext = this.selectedFile.name.split(".").pop();
        if (this.selectedFile.initiate) {
          if (pdfSupport(ext)) {
            if (
              origin === "https://ag-appsvc01.azurewebsites.net" ||
              origin === "https://ag-appsvc05.azurewebsites.net"
            ) {
              return `https://ag-appsvc04.azurewebsites.net/web/viewer.html?tId=${this.$store.state.session.tenantId}&uploadId=${this.selectedFile.id}&type=1`;
            } else if (origin === "https://trial.ezofis.com") {
              return `https://trial.ezofis.com/docsviewer/index.html?tId=${this.$store.state.session.tenantId}&uploadId=${this.selectedFile.id}&type=1`;
            } else if (origin === "https://app.ezofis.com1") {
              return `https://ezdocsviewer.azurewebsites.net/web/viewer.html?tId=${this.$store.state.session.tenantId}&uploadId=${this.selectedFile.id}&type=1`;
            } else {
              return `${origin}/PDFViewer/web/viewer.html?tId=${this.$store.state.session.tenantId}&uploadId=${this.selectedFile.id}&type=1`;
            }
          } else if (origin === "http://52.172.32.88") {
            return `http://52.172.32.88/DocsViewer/index.html?tId=${this.$store.state.session.tenantId}&uploadId=${this.selectedFile.id}&type=1`;
          } else {
            return `${process.env.VUE_APP_API_URL}/uploadandindex/view/${this.$store.state.session.tenantId}/${this.selectedFile.id}/1`;
          }
        } else {
          if (pdfSupport(ext)) {
            if (
              origin === "https://ag-appsvc01.azurewebsites.net" ||
              origin === "https://ag-appsvc05.azurewebsites.net"
            ) {
              return `https://ag-appsvc04.azurewebsites.net/web/viewer.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.id}&type=2`;
            } else if (origin === "https://trial.ezofis.com") {
              return `https://trial.ezofis.com/docsviewer/index.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.id}&type=2&filename=${this.selectedFile.name}`;
            } else if (origin === "https://app.ezofis.com1") {
              return `https://ezdocsviewer.azurewebsites.net/web/viewer.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.id}&type=2`;
            } else {
              return `${origin}/PDFViewer/web/viewer.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.id}&type=2&filename=${this.selectedFile.name}`;
            }
          } else if (!htmlSupport(ext) && origin === "http://52.172.32.88") {
            return `http://52.172.32.88/DocsViewer/index.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.id}&type=2&filename=${this.selectedFile.name}`;
          } else {
            return `${process.env.VUE_APP_API_URL}/file/view/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.selectedFile.repositoryId}/${this.selectedFile.id}/2`;
          }
        }
      }
    },

    openFile(fileObj) {
      this.selectedFile = fileObj;
      this.fileSheet = true;
    },

    async saveFormEntry(fieldsModel) {
      const unNecessaryKeys = [
        "itemid",
        "createdBy",
        "createdAt",
        "modifiedBy",
        "modifiedAt",
        "icon",
        "infoIcon",
        "selected",
        "emailStatus",
        "expandSubworkflow",
      ];

      const _fieldsModel = omit(fieldsModel, unNecessaryKeys);

      try {
        this.$store.commit("showLoadingBar");
        let formUpload = [];

        const { id, ...payload } = _fieldsModel;

        this.formFields.forEach((field) => {
          if (field.type === "FILE_UPLOAD" && _fieldsModel[field.id]) {
            let ids = [];

            _fieldsModel[field.id].forEach((item) => {
              if (item.fileId) ids.push(item.fileId);
            });

            if (ids.length) {
              formUpload.push({
                jsonId: field.id,
                fileIds: ids,
              });
            }

            delete payload[field.id];
          }
        });

        let input = {};
        if (id) {
          input = {
            fields: payload,
            formUpload: formUpload,
          };
        } else {
          let uploadedIdList = [];
          if (this.attachmentList.length) {
            this.attachmentList.forEach((file) => {
              uploadedIdList.push(file.id);
            });
          }
          input = {
            fields: payload,
            task: {
              comments: this.commentsList,
              fileIds: uploadedIdList,
            },
            formUpload: formUpload,
          };
        }

        const { data, error } = await form.saveFormEntry(
          this.formId,
          id || 0,
          input
        );

        if (error) {
          if (error.data) {
            let errorData = JSON.parse(error.data);
            console.log(errorData);
            let fields = JSON.parse(errorData.output);
            let fieldNames = [];
            fields.forEach((row) => {
              fieldNames.push(row.name);
            });
            this.$alert.warning("Already Exists!", fieldNames);
          }
          this.$alert.error(error);
          return;
        }
        if (id) {
          if (this.fileControlDeleteFile.length) {
            this.fileControlDeleteFile.forEach((file) => {
              this.fileControlDeletedFile(file, id);
            });

            this.fileControlDeleteFile = [];
          }
          this.$alert.success(`Form entry updated successfully`);
        } else {
          this.$alert.success(`Form entry added successfully`);
        }

        if (this.form.settings.general.qrFields) {
          if (this.form.settings.general.qrFields.length) {
            let entryId = data.output;
            this.formEntryId = id ? id : parseInt(entryId);
            this.isQRView = true;
          }
        } else {
          this.closeSheet();
          this.$emit("refresh");
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.$store.commit("hideLoadingBar");
      }
    },

    closeQRModal() {
      this.closeSheet();
      this.$emit("refresh");
    },

    async getComments() {
      this.tabs.find((tab) => {
        if (tab.value === "COMMENTS") {
          tab.count = 0;
        }
      });
      this.commentsList = [];
      if (this.formModel.id) {
        this.$store.commit("showLoadingBar");
        const { error, payload } = await form.getFormComments(
          this.formId,
          this.formModel.id
        );

        this.$store.commit("hideLoadingBar");

        if (error) {
          this.$alert.error(error);
          return;
        }
        if (payload.length) {
          this.commentsList = payload;
          this.tabs.find((tab) => {
            if (tab.value === "COMMENTS") {
              tab.count = this.commentsList.length;
            }
          });
        }
      }
    },

    async saveComment() {
      if (this.commentText) {
        if (this.formModel.id) {
          this.$store.commit("showLoadingBar");
          const { error } = await form.insertFormComment(
            this.formId,
            this.formModel.id,
            {
              comments: this.commentText,
            }
          );

          this.$store.commit("hideLoadingBar");

          if (error) {
            this.$alert.error(error);
            return;
          }
          this.commentText = "";
          this.getComments();
        } else {
          this.commentsList.push({
            comments: this.commentText,
            createdAt: new Date().toISOString(),
            createdBy: this.$store.state.session.id,
          });
          this.commentText = "";
        }
      }
    },

    async saveAdvancedComment() {
      if (this.advancedCommentText) {
        if (this.formModel.id) {
          this.$store.commit("showLoadingBar");
          const { error } = await form.insertFormComment(
            this.formId,
            this.formModel.id,
            {
              comments: this.advancedCommentText,
            }
          );

          this.$store.commit("hideLoadingBar");

          if (error) {
            this.$alert.error(error);
            return;
          }
          this.advancedCommentText = "";
          this.getComments();
          this.advancedCommentsSheet = false;
        } else {
          this.commentsList.push({
            comments: this.advancedCommentText,
            createdAt: new Date().toISOString(),
            createdBy: this.$store.state.session.id,
          });
          this.advancedCommentText = "";
          this.advancedCommentsSheet = false;
        }
      }
    },

    async uploadAttachments(e) {
      if (this.formModel.id) {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("formId", this.formId);
        formData.append("repositoryId", this.repositoryId);
        formData.append("entryId", this.formModel.id);
        formData.append("file", file);

        let filename = e.target.files[0].name;
        const ext = filename.split(".").pop();
        if (!fileSupport(ext)) {
          this.$alert.info("This file format is not supported");
          return;
        }
        this.$store.commit("showLoadingBar");
        try {
          const response = await axios({
            url: `${process.env.VUE_APP_API_URL}/form/taskAttachmentWithEntryId`,
            method: "POST",
            data: formData,
            headers: {
              Token: this.$store.state.identity.token,
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          });
          const { status, data } = response;
          if (status === 200 && data) this.$alert.success(`Document attached`);
          else throw response;
        } catch (error) {
          this.$alert.error("Error uploading file");
          console.error(error);
        }
        this.$store.commit("hideLoadingBar");
        e.target.value = "";
        this.attachmentLoad = setInterval(() => this.getAttachments(), 5000);
      } else {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file);
        formData.append("formId", this.formId);
        formData.append("repositoryId", this.repositoryId);
        let filename = e.target.files[0].name;
        const ext = filename.split(".").pop();
        if (!fileSupport(ext)) {
          this.$alert.info("This file format is not supported");
          return;
        }
        try {
          const response = await axios({
            url: `${process.env.VUE_APP_API_URL}/uploadAndIndex/upload`,
            method: "POST",
            data: formData,
            headers: {
              Token: this.$store.state.identity.token,
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          });

          const { status, data } = response;
          if (status !== 200) {
            throw "Invalid response";
          }
          let filesList = JSON.parse(data);
          this.attachmentList.push({
            id: Number(filesList.fileId),
            name: file.name,
            createdAt: new Date().toISOString(),
            createdByEmail: this.$store.state.session.email,
            size: file.size,
            initiate: true,
            checked: false,
          });
          e.target.value = "";
        } catch (error) {
          console.error(error);
          this.$alert.error(`error attaching file`);
        }
      }
    },

    async getAttachments() {
      this.tabs.find((tab) => {
        if (tab.value === "ATTACHMENTS") {
          tab.count = 0;
        }
      });
      if (this.formModel.id) {
        let count = this.attachmentList.length;
        this.$store.commit("showLoadingBar");
        const { error, payload } = await form.getFormAttachments(
          this.formId,
          this.formModel.id
        );

        this.$store.commit("hideLoadingBar");

        if (error) {
          this.$alert.error(error);
          return;
        }
        if (payload.length) {
          if (count !== payload.length) {
            this.attachmentList = payload.map((item) => {
              return {
                ...item,
                checked: false,
              };
            });
            clearInterval(this.attachmentLoad);
          }
          this.tabs.find((tab) => {
            if (tab.value === "ATTACHMENTS") {
              tab.count = this.attachmentList.length;
            }
          });
        } else {
          this.attachmentList = [];
        }
      } else {
        this.attachmentList = [];
      }
    },

    async attachmentDelete(file) {
      this.$store.commit("showLoadingBar");
      if (file.initiate) {
        const { error } = await uploadAndIndex.deleteUploadedFiles([file.id]);
        if (error) {
          this.$alert.error(error);
          return;
        }
        this.$store.commit("hideLoadingBar");
        this.attachmentList = this.attachmentList.filter(
          (row) => row.id !== file.id
        );
      } else {
        const { error } = await repository.fileDelete(
          this.repositoryId,
          file.id
        );
        if (error) {
          this.$alert.error(error);
          return;
        }
        this.$store.commit("hideLoadingBar");
        this.getAttachments();
      }
    },

    async uploadFilesFromForm(files, control, fieldId) {
      console.log(files, control);
      this.formUploadFiles = [];

      let formFields = [];
      if (control && control.length) {
        formFields = control;
      }
      if (files.length) {
        for (let file of files) {
          const formData = new FormData();
          // files.forEach(async (file) => {
          console.log(file);
          formData.append("formId", this.formId);
          formData.append("repositoryId", this.repositoryId);
          formData.append("file", file);
          formData.append("filename", file.name);
          if (formFields.length) {
            formData.append("formFields", JSON.stringify(formFields));
          }

          try {
            const response = await axios({
              url: `${process.env.VUE_APP_API_URL}/uploadAndIndex/upload`,
              method: "POST",
              data: formData,
              headers: {
                Token: this.$store.state.identity.token,
                Accept: "application/json",
                "Content-Type": "multipart/form-data",
              },
            });

            const { status, data } = response;
            if (status !== 200) {
              throw "Invalid response";
            }
            file.createdAt = this.$day.newDateTime();
            file.createdBy = this.$store.state.session.email;
            let filesList = JSON.parse(data);
            file.fileId = filesList.fileId;
            filesList.OCRfieldlist.forEach((hasFile) => {
              let fieldId = this.formFields.find(
                (item) => item.label === hasFile.name
              );
              if (fieldId) {
                this.workflowModel[fieldId.id] = fieldId.value;
              }
            });
          } catch (error) {
            console.error(error);
            this.$alert.error(`error attaching file`);
          }
          // });
        }

        let _files = Array.from(files);
        console.log(_files);
        this.formUploadFiles = [];
        for (let file of _files) {
          // const base64String = await fileToBase64(file);

          this.formUploadFiles.push({
            name: file.name,
            size: file.size,
            // file: base64String,
            fileId: file.fileId,
            createdBy: file.createdBy,
            createdAt: file.createdAt,
            fieldId: fieldId,
          });
        }
      }
    },

    deletedFiles(ids) {
      // this.fileControlDeleteFile = [];
      this.fileControlDeleteFile.push(ids);
    },

    async fileControlDeletedFile(file, id) {
      this.$store.commit("showLoadingBar");
      let fileIds = [];
      file.forEach((item) => {
        if (item.id) fileIds.push(item.id);
      });
      let input = {
        ids: fileIds,
        formInfo: {
          formId: this.formId,
          formEntryId: id,
          jsonId: file[0].jsonId,
        },
      };
      const { error } = await repository.fileControlDeleteFile(
        this.repositoryId,
        input
      );

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      // this.fileControlDeleteFile = [];
    },
  },
};
</script>

<style lang="scss" scoped>
#sheet {
  .badge {
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    border-radius: 9px;
    font-size: 12px;
    background: $primary;
    color: #fff;
    padding: 0 5px;
    vertical-align: top;
    margin-top: -30px;
    margin-right: -20px;
    position: absolute;
    z-index: 1;
  }

  .leftBorder {
    border-left: 1px solid var(--divider-color);
  }

  #action-btn.tabButtons {
    height: 52px;
    width: 52px;
  }
}

#sheet .attachmentList {
  padding: 12px;
  border-bottom: 1px solid var(--divider-color);
  &:hover {
    cursor: pointer;
    background: #e0f7fa;
  }

  // &:not(:last-child) {
  //   border-bottom: 1px solid var(--divider-color);
  // }

  &.is-selected.mini-mode::after {
    content: "";
    position: absolute;
    right: 0;
    top: 10px;
    width: 4px;
    height: 75%;
    background-color: var(--secondary);
    border-radius: 4px 0px 0px 4px;
  }

  .thumbnail {
    height: 80px;
    width: 80px;
    border: 1px solid var(--divider-color);
    border-radius: 4px 0px 0px 4px;
  }

  .avatar {
    width: 48px;
    height: 48px;
  }

  .mini-avatar {
    width: 18px;
    height: 18px;
  }

  .title {
    font-weight: 600;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
  }

  .meta {
    @extend .text-sm;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: var(--icon-color-inverted);
  }
}

#sheet .attachmentFileHeader {
  display: flex;
  align-items: center;
  text-transform: none !important;
  .avatar {
    width: 36px;
    height: 36px;
  }

  .filename {
    font-size: 16px !important;
  }

  .meta {
    @extend .text-sm;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: var(--icon-color-inverted);
    font-weight: normal !important;
  }
}

#sheet .attachmentFileFrame {
  min-height: calc(100vh - 61px);
  background-color: var(--body-bg-color);
  display: flex;

  .file-list {
    flex: 1;
  }

  .aside {
    width: 380px;
    background-color: var(--component-bg-color);
  }

  #file-frame {
    width: 100%;
    height: 100%;
  }
}

#sheet {
  #action-btn.commentAction {
    height: 32px !important;
    width: 32px !important;
  }
}
</style>
