<template>
  <AppLayout>
    <!-- entries -->

    <ViewWrapper
      title="form entries"
      tab="form-entries-browse"
      :tabs="tabs"
      :breadcrumbs="breadcrumbs"
      @quickHelp="showQuickHelp"
    >
      <!-- header -->

      <template #header-actions>
        <BaseButton
          v-if="isEntryForm"
          icon="eva-plus"
          label="add entry"
          class="q-ml-md"
          @click="addFormEntry"
        />
      </template>

      <!-- ... -->

      <!-- quick help -->

      <QuickHelp v-model="quickHelp" />

      <!-- ... -->

      <div id="form-entries-browse">
        <Portal to="action-bar">
          <keep-alive>
            <ItemActions
              v-if="actionItems"
              item="form entry"
              :columns.sync="columns"
              :group-by.sync="groupBy"
              :mode="mode"
              :order.sync="sortBy.order"
              :filter-by.sync="filterBy"
              :active-view.sync="activeView"
              :criteria.sync="sortBy.criteria"
              :import-data="true"
              :data-imported="dataImported"
              :form-id="formId"
              module="form"
              :module-id="formId"
              :delete-btn="itemSelected"
              :send-invite="sendInviteBtn && itemSelected"
              @upload="$refs.input.click()"
              @refresh="getFormEntries"
              @search="searchRow"
              @close="closeImport"
              @update="getFormEntries"
              @delete="selectedDelete"
              @restore="restoreSeletedItems"
              @export="export_Data"
              @invite="sendInvite"
            />
          </keep-alive>
        </Portal>

        <div class="content">
          <ItemActionChips
            :sort-by="sortBy"
            :columns="columns"
            :group-by="groupBy"
            :filter-by="filterBy"
            @clearSortBy="clearSortBy"
            @clearGroupBy="clearGroupBy"
            @clearFilterBy="clearFilterBy"
          />

          <FormEntryGrid
            v-if="activeView === 'GRID'"
            :mode="mode"
            :items="items"
            :columns="columns"
            @edit="editFormEntry"
            @more="viewFormEntry"
            @delete="showDeleteConfirmation"
            @restore="showRestoreConfirmation"
            @info="showQRImage"
            @viewFile="viewFile"
          />

          <FormEntryList
            v-if="activeView === 'LIST'"
            :mode="mode"
            :items.sync="items"
            :columns="columns"
            :select-all="overallSelectAll"
            :resend="sendInviteBtn"
            @edit="editFormEntry"
            @more="viewFormEntry"
            @delete="showDeleteConfirmation"
            @restore="showRestoreConfirmation"
            @info="showQRImage"
            @update-selected-all="updateSelectAll"
            @viewFile="viewFile"
            @resend="resendInvite"
          />
        </div>

        <Pagination
          :total-items="totalItems"
          :current-page.sync="currentPage"
          :items-per-page.sync="itemsPerPage"
          @loadAll="loadAllFormEntries"
        />

        <FormEntrySheet
          v-model="isFormEntrySheetVisible"
          :form-id="formId"
          :form="form"
          :form-model="selectedFormEntry"
          :mode="entryMode"
          :repository-id="repositoryId"
          :form-fields="formFields"
          @refresh="getFormEntries"
        />

        <ConfirmDeleteFormEntry
          v-model="isDeleteConfirmationVisible"
          :form-entry-id="selectedFormEntry?.id"
          :is-multi-delete-confirmation-visible="isMultiDelete"
          :number-of-files-delete="numberOfFilesDelete"
          :is-all-selected="isAllSelected"
          :total-items="totalItems"
          :mode="mode"
          :confirm-all-delete="checkboxConfirmALLDelete"
          @delete="updateFormEntry"
          @multidelete="updateMultipleFormEntry"
          @confirmAllDelete="ConfirmAllDelete"
        />

        <ConfirmRestoreFormEntry
          v-model="isRestoreConfirmationVisible"
          :form-entry-id="selectedFormEntry?.id"
          :multiple-restore="multipleRestore"
          :number-of-restore="numberOfRestore"
          @restore="updateFormEntry"
          @multirestore="multirestore"
        />

        <QRView
          v-model="isQRView"
          :form-id="formId"
          :form-entry-id="selectedFormEntry?.id"
        />
      </div>

      <input
        ref="input"
        type="file"
        accept=".csv,.xls,.xlsx"
        class="hidden"
        @change="uploadFile"
      />

      <Sheet
        v-model="fileSheet"
        no-padding
        width="100vw"
        @input="fileSheet = false"
      >
        <!-- title -->

        <template #title>
          <template v-if="selectedFile">
            <div class="attachmentFileHeader">
              <div class="avatar q-ml-sm">
                <FileIcon
                  :mime-type="
                    fileType(selectedFile.fileName || selectedFile.name)
                  "
                />
              </div>

              <div class="q-ml-md">
                <div class="filename">
                  {{ selectedFile.fileName || selectedFile.name }}
                </div>

                <div class="meta">
                  <div>Archived At: {{ selectedFile.createdAt }}</div>
                  <BaseIcon name="mdi-circle-small" />
                  <div>Archived By: {{ selectedFile.createdBy }}</div>
                  <div v-if="selectedFile.size">
                    <BaseIcon name="mdi-circle-small" />
                    File Size: {{ fileSize(selectedFile.size) }}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </template>
        <template v-if="selectedFile">
          <div class="attachmentFileFrame">
            <div class="file-list">
              <iframe
                id="file-frame"
                ref="myFiles"
                :src="openFile()"
                frameborder="0"
              ></iframe>
            </div>
          </div>
        </template>
      </Sheet>
    </ViewWrapper>
  </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/app/AppLayout.vue";
import ViewWrapper from "@/components/common/view-wrapper/ViewWrapper.vue";
import QuickHelp from "@/components/common/QuickHelp.vue";
import { form, portal } from "@/api/factory.js";
import DataType from "@/constants/data-type.js";
import { Portal } from "portal-vue";
import Pagination from "@/components/common/display/Pagination.vue";
import ItemActions from "@/components/common/display/item-actions/ItemActions.vue";
import ItemActionChips from "@/components/common/display/ItemActionChips.vue";
import FormEntryGrid from "./components/FormEntryGrid.vue";
import FormEntryList from "./components/FormEntryList.vue";
import FormEntrySheet from "./components/FormEntrySheet.vue";
import ConfirmDeleteFormEntry from "./components/ConfirmDeleteFormEntry.vue";
import ConfirmRestoreFormEntry from "./components/ConfirmRestoreFormEntry.vue";
import QRView from "@/components/common/QRView.vue";
import axios from "axios";
import { exportData } from "@/helpers/export-excel-data";
import { pdfSupport, htmlSupport } from "@/helpers/file-format.js";
import Sheet from "@/components/common/popup/Sheet.vue";
import FileIcon from "@/components/common/FileIcon.vue";
import formatBytes from "@/helpers/format-bytes.js";
import { isEmpty } from "lodash-es";

export default {
  name: "FormEntries",

  components: {
    AppLayout,
    ViewWrapper,
    QuickHelp,
    Portal,
    ItemActionChips,
    ItemActions,
    FormEntryGrid,
    FormEntryList,
    FormEntrySheet,
    Pagination,
    ConfirmDeleteFormEntry,
    ConfirmRestoreFormEntry,
    QRView,
    Sheet,
    FileIcon,
  },

  props: {
    formId: {
      type: String,
      default: "",
    },

    mode: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      tabs: [
        {
          id: this.$nano.id(),
          label: "browse",
          icon: "eva-folder-outline",
          route: "form-entries-browse",
        },
        {
          id: this.$nano.id(),
          label: "trash",
          icon: "eva-trash-2-outline",
          route: "form-entries-trash",
        },
      ],
      breadcrumbs: [
        {
          id: this.$nano.id(),
          label: "dashboard",
          route: "dashboard",
        },
        {
          id: this.$nano.id(),
          label: "forms",
          route: "forms-browse",
        },
      ],
      formName: "",
      quickHelp: false,
      columns: [],
      activeView: "GRID",
      selectedFormEntry: {},
      isFormEntrySheetVisible: false,
      isDeleteConfirmationVisible: false,
      isRestoreConfirmationVisible: false,
      sortBy: {
        criteria: "",
        order: "DESC",
      },
      groupBy: "",
      filterBy: [],
      totalItems: 0,
      itemsPerPage: 50,
      currentPage: 1,
      form: null,
      entryMode: "NEW",
      formEntries: [],
      formEntriesData: [],
      isQRView: false,
      actionItems: false,
      dataImported: false,
      repositoryId: 0,
      numberOfFilesDelete: 0,
      itemSelected: false,
      isAllSelected: false,
      confirmAllDelete: false,
      multipleRestore: false,
      numberOfRestore: 0,
      checkboxConfirmALLDelete: false,
      overallSelectAll: false,
      isMultiDelete: false,
      selectedFile: {},
      fileSheet: false,
      sendInviteBtn: true,
      portal: {},
    };
  },

  computed: {
    isEntryForm() {
      if (!this.form) {
        return false;
      }

      return this.form.settings.general.type !== "WORKFLOW";
    },

    formFields() {
      const fields = [];

      if (!this.form) {
        return fields;
      }

      for (let panel of this.form.panels) {
        for (let field of panel.fields) {
          fields.push({
            id: field.id,
            type: field.type,
            label: field.label,
          });
        }
      }

      return fields;
    },

    items() {
      return this.formEntries.map(({ key, value }) => ({
        key: this.groupBy,
        value: key,
        data: value.map((entry) => ({
          id: entry.itemid,
          itemid: `Entry #${entry.itemid}`,
          createdBy: entry.createdByEmail,
          createdAt: this.$day.format(entry.createdAt),
          modifiedBy: entry.modifiedByEmail,
          modifiedAt: this.$day.format(entry.modifiedAt),
          icon: "mdi-text-box",
          infoIcon: this.checkQR,
          emailStatus: entry.emailStatus,
          ...this.getFormFieldsData(entry),
        })),
      }));
    },

    checkQR() {
      if (!this.form) {
        return "";
      }
      if (this.form.settings.general.qrFields) {
        if (this.form.settings.general.qrFields.length) {
          return "mdi-qrcode";
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
  },

  watch: {
    formId: {
      deep: true,
      immediate: true,
      handler() {
        this.getPortals();
      },
    },

    formFields: {
      immediate: true,
      deep: true,
      handler() {
        let staticColumns = [
          {
            id: this.$nano.id(),
            name: "itemid",
            label: "Entry #",
            type: "TITLE",
            isVisible: true,
            isSortable: true,
            isGroupable: false,
            dataType: DataType.SHORT_TEXT,
          },
          {
            id: this.$nano.id(),
            name: "createdBy",
            label: "Created By",
            type: "SUBTITLE",
            isVisible: true,
            isSortable: true,
            isGroupable: true,
            dataType: DataType.SINGLE_SELECT,
            default: "USERS",
          },
          {
            id: this.$nano.id(),
            name: "createdAt",
            label: "Created At",
            type: "NORMAL",
            isVisible: false,
            isSortable: true,
            isGroupable: false,
            dataType: DataType.DATE_TIME,
          },
          {
            id: this.$nano.id(),
            name: "modifiedBy",
            label: "Modified By",
            type: "NORMAL",
            isVisible: false,
            isSortable: true,
            isGroupable: true,
            dataType: DataType.SINGLE_SELECT,
            default: "USERS",
          },
          {
            id: this.$nano.id(),
            name: "modifiedAt",
            label: "Modified At",
            type: "NORMAL",
            isVisible: false,
            isSortable: true,
            isGroupable: false,
            dataType: DataType.DATE_TIME,
          },
        ];

        const dynamicColumns = this.formFields
          .filter((field) => this.isValidField(field.type))
          .map((field) => ({
            id: this.$nano.id(),
            name: field.id,
            label: field.label || field.type,
            type: "NORMAL",
            isVisible: true,
            isSortable: false,
            isGroupable: false,
            dataType: field.type,
            apiLoad: true,
          }));

        const [entryId, ...rest] = staticColumns;
        this.columns = [entryId, ...dynamicColumns, ...rest];
        if (dynamicColumns.length > 8) {
          this.activeView = "LIST";
        }
      },
    },
  },

  mounted() {
    this.$watch(
      (vm) => [vm.mode, vm.sortBy, vm.groupBy, vm.currentPage, vm.itemsPerPage],
      () => this.getFormEntries(),
      { deep: true, immediate: true }
    );

    this.getForm();
  },

  methods: {
    isValidField(fieldType) {
      return [
        "SHORT_TEXT",
        "LONG_TEXT",
        "NUMBER",
        "COUNTER",
        "CALCULATED",
        "DATE",
        "TIME",
        "DATE_TIME",
        "SINGLE_SELECT",
        "MULTI_SELECT",
        "SINGLE_CHOICE",
        "MULTIPLE_CHOICE",
        "CHIPS",
        "FILE_UPLOAD",
      ].includes(fieldType);
    },

    showQuickHelp() {
      this.quickHelp = true;
    },

    clearSortBy() {
      this.sortBy = {
        criteria: "",
        order: "DESC",
      };
    },

    clearGroupBy() {
      this.groupBy = "";
    },

    getFormFieldsData(entry, exportOption) {
      const data = {};

      for (let field of this.formFields) {
        const complexTypes = [
          "TABLE",
          "MATRIX",
          "FILL_IN_THE_BLANKS",
          "TABS",
          "POPUP",
          "FILE_UPLOAD",
        ];
        if (exportOption) {
          data[field.label] = complexTypes.includes(field.type)
            ? !isEmpty(entry[field.id])
              ? JSON.parse(entry[field.id])
              : ""
            : entry[field.id];
        } else {
          data[field.id] = complexTypes.includes(field.type)
            ? !isEmpty(entry[field.id])
              ? JSON.parse(entry[field.id])
              : ""
            : entry[field.id];
        }
      }

      return data;
    },

    findFormEntry(formEntryId) {
      const formEntries = [];
      this.items.forEach((item) => formEntries.push(...item.data));
      return formEntries.find((entry) => entry.id === formEntryId);
    },

    addFormEntry() {
      this.entryMode = "NEW";

      this.selectedFormEntry = {};
      this.isFormEntrySheetVisible = true;
    },

    editFormEntry(formEntryId) {
      this.entryMode = "EDIT";

      this.selectedFormEntry = this.findFormEntry(formEntryId);
      this.isFormEntrySheetVisible = true;
    },

    viewFormEntry(formEntryId) {
      this.entryMode = "VIEW";

      this.selectedFormEntry = this.findFormEntry(formEntryId);
      this.isFormEntrySheetVisible = true;
    },

    showDeleteConfirmation(formEntryId) {
      this.selectedFormEntry = this.findFormEntry(formEntryId);
      this.isDeleteConfirmationVisible = true;
      this.isMultiDelete = false;
    },

    showRestoreConfirmation(formEntryId) {
      this.selectedFormEntry = this.findFormEntry(formEntryId);
      this.isRestoreConfirmationVisible = true;
      this.multipleRestore = false;
      this.numberOfRestore = 0;
    },

    loadAllFormEntries() {
      this.itemsPerPage = this.totalItems;
      this.currentPage = 1;
    },

    showQRImage(formEntryId) {
      this.selectedFormEntry = this.findFormEntry(formEntryId);
      this.isQRView = true;
    },

    /* api functions */

    async getForm() {
      this.$store.commit("showLoadingBar");

      const { error, payload } = await form.getForm(this.formId);

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.formName = payload.name;
      this.breadcrumbs.push({
        id: this.$nano.id(),
        label: this.formName,
        route: "forms-browse",
      });
      this.breadcrumbs.push({
        id: this.$nano.id(),
        label: "entries",
        route: "",
      });
      this.repositoryId = payload.repositoryId;
      this.form = JSON.parse(payload.formJson);
    },

    async getFormEntries() {
      this.formEntries = [];
      this.formEntriesData = [];
      this.actionItems = true;
      this.checkboxConfirmALLDelete = false;
      this.overallSelectAll = false;
      let portalId = 0;
      if (this.portal) {
        portalId = this.portal.id;
      }
      this.$store.commit("showLoadingBar");

      const { error, payload } = await form.getFormEntries(this.formId, {
        mode: this.mode,
        sortBy: this.sortBy,
        groupBy: this.groupBy,
        filterBy: this.filterBy,
        itemsPerPage: this.itemsPerPage,
        currentPage: this.currentPage,
        portalId: portalId,
      });

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      const { data, meta } = payload;

      this.formEntries = data || [];
      this.formEntriesData = data;
      this.totalItems = meta?.totalItems || 0;
    },

    async updateFormEntry(payload) {
      this.$store.commit("showLoadingBar");
      let input = {
        fields: payload,
      };
      const { error } = await form.saveFormEntry(
        this.formId,
        this.selectedFormEntry.id,
        input
      );

      if (error) {
        this.$alert.error(error);
        return;
      }

      this.$store.commit("hideLoadingBar");

      const mode = payload.isDeleted ? "deleted" : "restored";
      const name = this.selectedFormEntry.itemid;
      this.$alert.success(`Form entry "${name}" ${mode} successfully`);

      this.getFormEntries();
    },

    async multirestore() {
      let id = [];
      if (!this.confirmAllDelete) {
        this.items.forEach((group) => {
          group.data.forEach((item) => {
            if (item.selected) {
              id.push(item.id);
            }
          });
        });
      }
      this.$store.commit("showLoadingBar");
      let input = {
        ids: id,
      };
      const { error } = await form.restoreMultipleFormEntry(this.formId, input);
      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.$alert.success(`Form entries restored successfully`);
      this.getFormEntries();
      this.itemSelected = false;
      this.isAllSelected = false;
    },

    async updateMultipleFormEntry() {
      this.$store.commit("showLoadingBar");
      let id = [];
      if (!this.confirmAllDelete) {
        this.items.forEach((group) => {
          group.data.forEach((item) => {
            if (item.selected) {
              id.push(item.id);
            }
          });
        });
      }

      let input = { ids: id };
      let type = this.mode === "TRASH" ? 1 : 0;
      const { error } = await form.deleteMultipleFormEntry(
        this.formId,
        type,
        input
      );
      this.$store.commit("hideLoadingBar");
      if (error) {
        this.$alert.error(error);
        return;
      }
      // const name = this.selectedFormEntry.itemid;
      // this.$alert.success(`Form entries deleted successfully`);
      this.getFormEntries();
      this.itemSelected = false;
      this.isAllSelected = false;
    },

    /* ... */

    searchRow(search) {
      if (search) {
        this.formEntries = this.formEntriesData.map(({ key, value }) => ({
          key: key,
          value: value.filter((row) => {
            for (let cell in row) {
              let index = this.columns.findIndex((column) => {
                return column.name === cell;
              });
              if (index >= 0 && row[cell]) {
                if (
                  String(row[cell]).toLowerCase().includes(search.toLowerCase())
                ) {
                  return row;
                }
              }
            }
          }),
        }));
      } else {
        this.formEntries = this.formEntriesData;
      }
    },

    async uploadFile(e) {
      const file = e.target.files[0];
      if (
        file.type === "text/csv" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.type === "application/vnd.ms-excel"
      ) {
        const formData = new FormData();
        formData.append("formId", this.formId);
        formData.append("file", file);
        try {
          this.$store.commit("showLoadingBarSave");
          const response = await axios({
            url: `${process.env.VUE_APP_API_URL}/form/uploadMasterFile`,
            method: "POST",
            data: formData,
            headers: {
              Token: this.$store.state.identity.token,
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          });
          this.$store.commit("hideLoadingBarSave");
          const { status, data } = response;
          if (status === 200 && data)
            this.$alert.success(`Data imported successfully`);
          else throw response;
        } catch (error) {
          this.$alert.error("Error uploading file");
          console.error(error);
        }
        this.dataImported = true;
        e.target.value = "";
        //this.getFormEntries();
      } else {
        this.$alert.error("Please upload a csv/excel file");
      }
    },

    closeImport() {
      this.dataImported = false;
    },

    clearFilterBy(id, index) {
      this.filterBy[id].filters.splice(index, 1);
      if (this.filterBy[id].filters.length == 0) {
        this.filterBy[id].filters = [];
        this.filterBy.splice(id, 1);
      }
      if (this.filterBy.length == 0) this.filterBy = [];
      this.getFormEntries();
    },

    selectedDelete() {
      if (this.isAllSelected) {
        this.isDeleteConfirmationVisible = true;
        this.isMultiDelete = false;
      } else if (this.itemSelected) {
        let numberOfSelected = 0;
        this.items.forEach((group) => {
          group.data.forEach((item) => {
            if (item.selected) {
              numberOfSelected++;
            }
          });
        });
        this.isDeleteConfirmationVisible = true;
        this.isMultiDelete = true;
        this.showMultiDeleteConfirmation(numberOfSelected);
      }
    },

    updateSelectAll(value) {
      if (!value) {
        const newValue = this.items.some((group) =>
          group.data.some((item) => item.selected === true)
        );
        this.itemSelected = newValue;
        this.isAllSelected = value;
      } else {
        let numberOfSelected = 0;
        this.items.forEach((group) => {
          group.data.forEach((item) => {
            if (item.selected) {
              numberOfSelected++;
            }
          });
        });
        this.showMultiDeleteConfirmation(numberOfSelected);
        this.itemSelected = value;
        this.isAllSelected = value;
      }
    },

    showMultiDeleteConfirmation(numberOfListDelete) {
      this.numberOfFilesDelete = numberOfListDelete;
    },

    ConfirmAllDelete(value) {
      this.confirmAllDelete = value;
    },

    restoreSeletedItems() {
      this.isRestoreConfirmationVisible = true;
      this.multipleRestore = true;
      let numberOfSelected = 0;
      this.items.forEach((group) => {
        group.data.forEach((item) => {
          if (item.selected) {
            numberOfSelected++;
          }
        });
      });
      this.numberOfRestore = numberOfSelected;
    },

    async export_Data() {
      this.$store.commit("showLoadingBar");

      const { error, payload } = await form.getFormEntries(this.formId, {
        mode: this.mode,
        sortBy: this.sortBy,
        groupBy: this.groupBy,
        filterBy: this.filterBy,
        itemsPerPage: this.itemsPerPage,
        currentPage: this.currentPage,
      });

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      const { meta, data } = payload;
      if (meta.totalItems && data.length) {
        let exportRecords = [];
        exportRecords = data[0].value.map((entry) => ({
          ...this.getFormFieldsData(entry, true),
          "Created By": entry.createdByEmail,
          "Created At": this.$day.format(entry.createdAt),
          "Modified By": entry.modifiedByEmail || "",
          "Modified At": entry.modifiedAt
            ? this.$day.format(entry.modifiedAt)
            : "",
        }));
        exportData(
          exportRecords,
          this.form.settings.general.name + " Master Entry Data"
        );
      } else {
        //this.$alert.info("No data found for export");
      }
    },

    viewFile(fileObj) {
      this.selectedFile = fileObj;
      let origin = location.origin;
      if (
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net"
      ) {
        window.open(this.openFile());
      } else {
        this.fileSheet = true;
      }
    },

    openFile() {
      let file = this.selectedFile;

      let origin = location.origin.replace(
        "http://localhost:8080",
        "http://52.172.32.88"
      );
      if (file.fileName) {
        let ext = file.fileName.split(".").pop();
        if (pdfSupport(ext)) {
          let action = "";

          if (
            origin === "https://ag-appsvc01.azurewebsites.net" ||
            origin === "https://ag-appsvc05.azurewebsites.net"
          ) {
            return `https://ag-appsvc04.azurewebsites.net/web/viewer.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${file.repositoryId}&itemId=${file.itemId}&wId=${file.workflowId}&pId=${file.processId}&type=2&filename=${file.fileName}`;
          } else if (origin === "https://trial.ezofis.com") {
            return `https://trial.ezofis.com/docsviewer/index.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${file.repositoryId}&itemId=${file.itemId}&wId=${file.workflowId}&pId=${file.processId}&type=2&filename=${file.fileName}${action}`;
          } else if (origin === "https://app.ezofis.com1") {
            return `https://ezdocsviewer.azurewebsites.net/web/viewer.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${file.repositoryId}&itemId=${file.itemId}&wId=${file.workflowId}&pId=${file.processId}&type=2&filename=${file.fileName}${action}`;
          } else {
            return `${origin}/PDFViewer/web/viewer.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${file.repositoryId}&itemId=${file.itemId}&wId=${file.workflowId}&pId=${file.processId}&type=2&filename=${this.selectedFile.name}${action}`;
          }
        } else if (!htmlSupport(ext) && origin === "http://52.172.32.88") {
          return `http://52.172.32.88/DocsViewer/index.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${file.repositoryId}&itemId=${file.itemId}&wId=${file.workflowId}&pId=${file.processId}&type=2&filename=${file.fileName}`;
        } else {
          return `${process.env.VUE_APP_API_URL}/file/view/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${file.repositoryId}/${file.itemId}/2`;
        }
      }
    },

    fileType(fileName) {
      if (fileName) return fileName.split(".").reverse()[0].slice(0, 4);
      else "";
    },

    fileSize(size) {
      return formatBytes(size);
    },

    async getPortals() {
      this.portal = {};
      this.sendInviteBtn = false;
      const { error, payload } = await portal.getPortalList({
        mode: this.mode,
        sortBy: this.sortBy,
        groupBy: this.groupBy,
        filterBy: [
          {
            filters: [
              {
                criteria: "settingsJson",
                condition: "CONTAINS",
                value:
                  '"loginType":"MASTER_LOGIN","formId":' + this.formId + ",",
              },
            ],
            groupCondition: "",
          },
        ],
        itemsPerPage: this.itemsPerPage,
        currentPage: this.currentPage,
      });

      if (error) {
        this.$alert.error(error);
        return;
      }

      const { data, meta } = payload;
      if (meta.totalItems) {
        if (data.length) {
          if (data[0].value.length) {
            this.portal = data[0].value[0];
            if (this.portal.settingsJson) {
              let settings = JSON.parse(this.portal.settingsJson);
              if (
                settings.authentication.loginType === "MASTER_LOGIN" &&
                settings.authentication.notification
              ) {
                this.sendInviteBtn = true;
                this.getFormEntries();
              }
            }
          }
        }
      }
    },

    async sendInvite() {
      let id = [];
      this.items.forEach((group) => {
        group.data.forEach((item) => {
          if (item.selected) {
            id.push(item.id);
          }
        });
      });

      let origin = location.origin;
      let linkText = "";
      if (
        origin === "https://app.ezofis.com" ||
        origin === "https://appuat.ezofis.com" ||
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net" ||
        origin === "https://trial.ezofis.com"
      ) {
        linkText = `${origin}/portals/${this.$store.state.session.tenantId}/${this.portal.id}`;
      } else {
        linkText = `${origin}/app/portals/${this.$store.state.session.tenantId}/${this.portal.id}`;
      }

      let settingsJson = JSON.parse(this.portal.settingsJson);

      let input = {
        formId: this.formId,
        entryIds: id,
        fieldName: settingsJson.authentication.usernameField,
        inviteUrl: linkText,
      };
      const { error } = await form.sendInvite(this.portal.id, input);

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.$alert.success(`Invite Sent`);
      this.getFormEntries();
      this.itemSelected = false;
      this.isAllSelected = false;
    },

    async resendInvite(formEntryId) {
      let id = [this.findFormEntry(formEntryId).id];

      let origin = location.origin;
      let linkText = "";
      if (
        origin === "https://app.ezofis.com" ||
        origin === "https://appuat.ezofis.com" ||
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net" ||
        origin === "https://trial.ezofis.com"
      ) {
        linkText = `${origin}/portals/${this.$store.state.session.tenantId}/${this.portal.id}`;
      } else {
        linkText = `${origin}/app/portals/${this.$store.state.session.tenantId}/${this.portal.id}`;
      }

      let settingsJson = JSON.parse(this.portal.settingsJson);

      let input = {
        formId: this.formId,
        entryIds: id,
        fieldName: settingsJson.authentication.usernameField,
        inviteUrl: linkText,
      };
      const { error } = await form.sendInvite(this.portal.id, input);

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.$alert.success(`Invite Sent`);
      this.getFormEntries();
      this.itemSelected = false;
      this.isAllSelected = false;
    },
  },
};
</script>

<style lang="scss" scoped>
#form-entries-browse {
  .content {
    padding-top: 8px;
    min-height: calc(100vh - 248px);
  }
}

#sheet .attachmentFileHeader {
  display: flex;
  align-items: center;
  text-transform: none !important;
  .avatar {
    width: 36px;
    height: 36px;
  }

  .filename {
    font-size: 16px !important;
  }

  .meta {
    @extend .text-sm;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: var(--icon-color-inverted);
    font-weight: normal !important;
  }
}

#sheet .attachmentFileFrame {
  min-height: calc(100vh - 61px);
  background-color: var(--body-bg-color);
  display: flex;

  .file-list {
    flex: 1;
  }

  .aside {
    width: 380px;
    background-color: var(--component-bg-color);
  }

  #file-frame {
    width: 100%;
    height: 100%;
  }
}
</style>
